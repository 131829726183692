import * as React from 'react';
import { Typography, Table, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination, ToggleButtonGroup, ToggleButton, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { addInsurance } from '../../app/store/actions/patientActions';
import { useState } from 'react';
import OrderCard from '../../app/components/OrderCard';
import { Add, DashboardCustomize, FilterAlt, Inbox, TableChart } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import InsuranceCard from '../../app/components/InsuranceCard';
import { getInsuranceProviders } from '../../app/store/actions/commonActions';
import Logo from "../../app/components/logo.png"

export const AddInsurances = ({ getInsuranceProviders, insuranceProviders, addInsurance, isLoading }) => {

    const navigate = useNavigate()

    useEffect(() => {
        getInsuranceProviders()
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const creation = {
            provider: data.get("provider"),
            number: data.get("number"),
            coverageStartDate: data.get("coverageStartDate"),
            coverageExpiryDate: data.get("coverageExpiryDate")
        }
        addInsurance(creation, navigate);
    }

    return (
        <Container component={"main"}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Image preview={false} src={Logo} height={160} width={160} />
                <Typography variant="h5" fontWeight="bold" textAlign="center">Add Insurance</Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <MainInput
                    margin="normal"
                    fullWidth
                    id="provider"
                    label="Insurance Provider"
                    name="provider"
                    select
                >
                    {insuranceProviders?.map((provider) => {
                        return <MenuItem value={provider._id}>
                            <Avatar src={provider.logo} sx={{ mr: 2 }} /><Typography>{provider.name}</Typography>
                        </MenuItem>
                    })}
                </MainInput>
                <MainInput
                    margin="normal"
                    required
                    fullWidth
                    id="number"
                    label="Number"
                    name="number"
                />
                <MainInput
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    id="coverageStartDate"
                    label="Coverage Start Date"
                    name="coverageStartDate"
                />
                <MainInput
                    margin="normal"
                    required
                    fullWidth
                    type="date"
                    id="coverageExpiryDate"
                    label="Coverage Expiry Date"
                    name="coverageExpiryDate"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, ...main_button }}
                >
                    Submit
                </Button>
                <Box className="mb-2" />
            </Box>
        </Container >
    );
}

const mapStateToProps = (state) => ({
    insuranceProviders: state?.records?.insuranceProviders,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading
});

const mapDispatchToProps = { getInsuranceProviders, addInsurance };

export default connect(mapStateToProps, mapDispatchToProps)(AddInsurances);