import { combineReducers } from "redux";
import waitingReducer from "./waitingReducer";
import authReducer from "./authReducer";
import recordsReducer from "./recordsReducer";


export default combineReducers({
    wait: waitingReducer,
    auth: authReducer,
    records: recordsReducer
});