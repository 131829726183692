import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, LOGOUT, LOGOUT_SUCCESS } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { config } from "../../config";
import { deleteObject } from "../../../core/aws";
import { isMobile } from "react-device-detect";

const currentLocale = Cookies.get("i18next") || "en";

export const deleteAccount = (data) => (dispatch, useState) => {
    dispatch({ type: LOGOUT });
    const token = useState()?.auth?.token

    delRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.delete)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
        });
};

export const checkPromo = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.checkPromo)
        .then((response) => {
            const { data } = response;
            notification.success({ message: "Promo Code Applied", style: { marginTop: "15vh" } })
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const checkout = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkout)
        .then((response) => {
            const { data } = response;
            const { redirect } = data;
            window.location.href = redirect
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const checkTransaction = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkTransaction)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getOrders = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.orders.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getEvents = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const createEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(`/user/events/${data?.newEvent._id}`);
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(-1);
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getFolders = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.folders)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const uploadFiles = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS,
            });
            navigate && navigate(-1)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const getUploads = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const editFile = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const deleteFile = (data, file) => async (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    await Promise.all([file].map(async (file) => {
        const params = {
            Bucket: config.bucketName,
            Key: file.key
        }
        await deleteObject(params);
    }))

    delRequest(data, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;

            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const downloadGallery = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    notification.info({ message: "This May Take a While", style: { marginTop: "15vh" } });
    if (isMobile) {
        notification.info({ message: "It is highly suggested to use a PC for downloads", style: { marginTop: "15vh" } });
    }

    getRequest({ ...data, responseType: "arraybuffer" }, undefined, token, endpoints.user.event.export)
        .then((response) => {
            notification.success({ message: "All Downloaded", style: { marginTop: "15vh" } });
            download(response.data, response.headers["filename"], "application/zip")
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getMessages = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.message)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const transferEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.event.transfer)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate("/user/events");
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const sendRequest = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.printingRequest)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            close && close();
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const redeemVoucher = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.redeem)
        .then((response) => {
            const { data } = response;
            const { order, message } = data;
            notification.success({ message: message, style: { marginTop: "15vh" } })
            navigate && navigate(`/user/events/create/${order._id}`);
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};