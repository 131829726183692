import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, LOGOUT, LOGOUT_SUCCESS } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { config } from "../../config";
import { deleteObject } from "../../../core/aws";
import { isMobile } from "react-device-detect";

export const deleteAccount = (data) => (dispatch, useState) => {
    dispatch({ type: LOGOUT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.auth.patient.delete)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
        });
};

export const getInsurances = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.patient.insurance)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const addInsurance = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.patient.insurance)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } })
            navigate && navigate(-1)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};