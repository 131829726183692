import AWS from 'aws-sdk';
import { config } from '../app/config';

AWS.config.update(config);
const bucket = new AWS.S3(config);

async function uploadFile(params) {
    return new Promise((resolve, reject) => {
        bucket.upload(params, (err, data) => {
            if (err) {
                console.error("Error uploading file:", err);
                reject(err);
            } else {
                console.log("File uploaded successfully:", data);
                resolve(data);
            }
        });
    });
}

async function deleteObject(params) {
    return new Promise((resolve, reject) => {
        bucket.deleteObject(params, (err, data) => {
            if (err) {
                console.error("Error deleting file:", err);
                reject(err);
            } else {
                console.log("File deleted successfully:", data);
                resolve(data);
            }
        });
    });
}

export { uploadFile, deleteObject }