import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, GUEST, GUEST_SUCCESS, GUEST_FAIL, LOGOUT_SUCCESS } from "../actions/types";

const initialState = {
    user: null,
    token: null,
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GUEST:
        case LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('OrmedexUser', JSON.stringify(payload.payload));
            localStorage.setItem('OrmedexToken', payload.token);
            return {
                ...state,
                user: payload.payload,
                token: payload.token,
                isLoading: false,
                isError: false,
            };
        case GUEST_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case LOGOUT_SUCCESS:
            localStorage.removeItem('OrmedexUser');
            localStorage.removeItem('OrmedexToken');
            return {
                ...initialState,
                isLoading: false,
                isError: false,
            };
        default:
            return state;
    }
}