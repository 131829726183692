import { Email, Facebook, Phone, WhatsApp } from "@mui/icons-material";
import { Button, Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import { main_button } from "./Styles";
import { isMobile } from "react-device-detect";

export default function InfoCard({ title, description, type, href, buttonText }) {

    const getIcon = () => {
        switch (type) {
            case "email": return <Email sx={{ mx: 1 }} />
            case "whatsapp": return <WhatsApp sx={{ mx: 1 }} />
            case "mobile": return <Phone sx={{ mx: 1 }} />
            case "facebook": return <Facebook sx={{ mx: 1 }} />
            default: break;
        }
    }

    return (
        <Card sx={{ borderRadius: 5, padding: 2, mx: 1, my: 1, minWidth: isMobile ? "100%" : "40%" }}>
            <CardContent>
                <Typography fontSize={30} fontWeight="bold">{title}</Typography>
                {/* <Typography variant="caption">{description}</Typography> */}
            </CardContent>
            <CardActionArea>
                <Button fullWidth sx={main_button} href={href} target="_blank">{getIcon()}{buttonText}</Button>
            </CardActionArea>
        </Card>
    )
}