import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import TextArea from "antd/lib/input/TextArea";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Select,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MuiPhoneNumber } from "material-ui-phone-number";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

export const main_button = {
  color: "var(--primaryColor)",
  bgcolor: "var(--secColor)",
  ":hover": {
    color: "white !important",
    bgcolor: "var(--terColor)",
  },
  ":disabled": {
    color: "white !important",
    bgcolor: "var(--terColor) !important",
  },
  borderRadius: 10,
  fontWeight: "bold",
  padding: 1,
};

export const sec_button = {
  color: "var(--secColor) !important",
  bgcolor: "var(--appBg)",
  ":hover": {
    color: "var(--appBg) !important",
    bgcolor: "var(--secColor)",
  },
  ":disabled": {
    color: "var(--mainWhite)",
    bgcolor: "gray",
  },
  borderRadius: 10,
  fontWeight: "bold",
  padding: 1,
};

export const ter_button = {
  color: "var(--appBg)",
  bgcolor: "var(--accColor)",
  ":hover": {
    color: "var(--accColor)",
    bgcolor: "var(--appBg)",
  },
  ":disabled": {
    color: "var(--appBg)",
    bgcolor: "gray",
  },
  borderRadius: 10,
  fontWeight: "bold",
  padding: 1,
};

export const confirm_button = {
  color: "var(--mainWhite)",
  bgcolor: "green",
  ":hover": {
    bgcolor: "lightgreen",
    color: "green",
  },
  ":disabled": {
    color: "var(--mainWhite)",
    bgcolor: "gray",
  },
  borderRadius: 0,
  fontWeight: "bold",
  padding: 1,
};

export const reject_button = {
  color: "white !important",
  bgcolor: "red !important",
  ":hover": {
    bgcolor: "white !important",
    color: "red !important",
  },
  ":disabled": {
    color: "white !important",
    bgcolor: "gray !important",
  },
  borderRadius: 10,
  fontWeight: "bold",
  padding: 1,
};

export const card_style = {
  padding: 2,
  marginY: 2,
  marginX: 2,
  ":hover": {
    "transition-duration": "0.5s",
    "box-shadow": "0px 5px 5px 0px rgba(15,44,101,0.75)",
    "-webkit-box-shadow": "0px 5px 5px 0px rgba(15,44,101,0.75)",
    "-moz-box-shadow": "0px 5px 5px 0px rgba(15,44,101,0.75)",
    cursor: "pointer",
  },
};

export const centered_flex_box = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const left_flex_box = {
  display: "flex",
  justifyContent: "flex-start",
};

export const right_flex_box = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

export const MainSearch = styled(TextField)({
  "& label": {
    color: "var(--secColor) !important",
  },
  "& label.Mui-focused": {
    color: "var(--secColor) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid var(--secColor)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--terColor)",
    },
    borderRadius: 50,
  },
});

export const MainInput = styled(TextField)({
  "& label": {
    color: "var(--secColor) !important",
  },
  "& label.Mui-focused": {
    color: "var(--secColor) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid var(--secColor)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--terColor)",
    },

    borderRadius: 2,
  },
});

export const MainDateInput = styled(DatePicker)({
  "& label": {
    color: "var(--secColor) !important",
  },
  "& label.Mui-focused": {
    color: "var(--secColor) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid var(--secColor)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--terColor)",
    },

    borderRadius: 2,
  },
});

export const MainDateTimeInput = styled(DateTimePicker)({
  "& label": {
    color: "var(--secColor) !important",
  },
  "& label.Mui-focused": {
    color: "var(--secColor) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid var(--secColor)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--terColor)",
    },

    borderRadius: 2,
  },
});

export const SecInput = styled(TextField)({
  "& label": {
    color: "var(--appBg) !important",
  },
  "& label.Mui-focused": {
    color: "var(--appBg) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    color: "var(--appBg) !important",
    "& fieldset": {
      border: "1px solid var(--primaryPurple)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--appBg)",
    },
    borderRadius: 2,
  },
});

export const TerInput = styled(TextField)({
  "& label": {
    color: "var(--appBg) !important",
  },
  "& label.Mui-focused": {
    color: "var(--appBg) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    outerHeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    color: "var(--appBg) !important",
    "& fieldset": {
      border: "1px solid var(--secPurple)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--appBg)",
    },
    borderRadius: 2,
  },
});

export const MainTextArea = ({
  name,
  autoComplete,
  autoFocus,
  label,
  id,
  required,
  lines,
  defaultValue,
}) => {
  return (
    <>
      <InputLabel sx={{ color: "black" }}>{label}</InputLabel>
      <TextArea
        name={name}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        id={id}
        aria-label={label}
        required={required}
        style={{
          borderColor: "var(--secColor)",
          borderRadius: 4,
          fontFamily: "sans-serif",
          backgroundColor: "var(--bgColor)",
          color: "var(--secColor)",
          height: { lines },
        }}
      />
    </>
  );
};

export const MainPassword = ({
  name,
  autoComplete,
  fullWidth,
  label,
  id,
  required,
  margin,
  sx,
  value,
  readOnly,
  error,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ width: "100%", ...sx }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "var(--secColor) !important" }}>
        {label}
        {required ? "*" : ""}
      </InputLabel>
      <OutlinedInput
        sx={{
          "& fieldset": {
            border: "1px solid var(--secColor)  !important",
          },
          "&.Mui-focused fieldset": {
            border: "2px solid var(--secColor)  !important",
          },
        }}
        id={id}
        name={name}
        margin={margin}
        required={required}
        autoComplete={autoComplete}
        fullWidth={fullWidth}
        type={showPassword ? "text" : "password"}
        readOnly={readOnly}
        value={value}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff sx={{ color: "var(--secColor)" }} />
              ) : (
                <Visibility sx={{ color: "var(--secColor)" }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
};

export const SecPassword = ({
  name,
  autoComplete,
  fullWidth,
  label,
  id,
  required,
  margin,
  sx,
  value,
  readOnly,
  error,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ width: "100%", ...sx }} variant="outlined">
      <InputLabel
        sx={{ color: "var(--appBg) !important" }}
        htmlFor="outlined-adornment-password"
      >
        {label}
        {required ? "*" : ""}
      </InputLabel>
      <OutlinedInput
        sx={{
          color: "var(--appBg) !important",
          "& fieldset": {
            border: "1px solid var(--primaryPurple)",
          },
          "& .Mui-focused fieldset": {
            borderColor: "var(--appBg)",
          },
        }}
        id={id}
        name={name}
        margin={margin}
        required={required}
        autoComplete={autoComplete}
        fullWidth={fullWidth}
        type={showPassword ? "text" : "password"}
        readOnly={readOnly}
        value={value}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
};

export const MainInputLabel = ({ required, id, title, color }) => {
  return (
    <InputLabel sx={{ color }} required={required} id={id}>
      {title}
    </InputLabel>
  );
};
