import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { About, AddInsurance, Contact, FAQs, ForgotPassword, Home, Insurances, Login, Privacy, Terms } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/faq" exact element={<FAQs />} />
      <Route path="/terms" exact element={<Terms />} />
      <Route path="/policy/privacy" exact element={<Privacy />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route exact element={<ProtectedRoute allowed={["Patient"]} />}>
        <Route path="/patient/insurances" exact element={<Insurances />} />
        <Route path="/patient/insurances/add" exact element={<AddInsurance />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Provider"]} />}>

      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
