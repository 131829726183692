import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, CardContent, Icon, IconButton, Divider, CardActions } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Logo from "../../app/components/logo.png"
import { Image } from 'antd';
import ImageComponent from '../../app/components/ImageComponent';
import { ArrowForward, Chat, ChatOutlined, Info, MedicalInformation, MedicalServices, MedicalServicesOutlined, Search, ShoppingBagOutlined, Today, VisibilityOutlined } from '@mui/icons-material';
import { BottomBar } from '../../app/components';

export const Home = ({ user, token }) => {

    const navigate = useNavigate();
    const role = token?.split(" ")[0]

    return (
        <div className="fullPage">
            <Container sx={{ minWidth: isMobile ? "100% !important" : "90% !important" }}>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Grid container p={5}>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Typography fontWeight={"bold"} variant='h2'>Ormedex</Typography>
                            <Divider sx={{ borderWidth: 3, borderColor: "var(--secColor)", width: "50%" }} />
                            {user && <Typography mt={2} variant='h5'>Hello, {user.name}</Typography>}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4}>
                            {role === "Provider" && !user?.application && <Card sx={{ my: 1 }}>
                                <CardContent sx={{ ...left_flex_box }}>
                                    <Info color="error" sx={{ mr: 1 }} /><Typography>You need to apply with your credentials to use Ormedex!</Typography>
                                </CardContent>
                                <CardContent>
                                    <NavLink className={"a2"}>Press to apply</NavLink>
                                </CardContent>
                            </Card>}
                            {role === "Provider" && user.status === "Pending" && user?.application && <Card sx={{ my: 1 }}>
                                <CardContent sx={{ ...left_flex_box }}>
                                    <Info color='warning' sx={{ mr: 1 }} /><Typography>We are now processing your application</Typography>
                                </CardContent>
                            </Card>}
                            {role === "Provider" && user.status === "Unverified" && user?.application && <Card sx={{ my: 1 }}>
                                <CardContent sx={{ ...left_flex_box }}>
                                    <Info color="error" sx={{ mr: 1 }} /><Typography>We were unable to verify your credentials</Typography>
                                </CardContent>
                                <CardContent>
                                    <NavLink className={"a2"}>Press to reapply</NavLink>
                                </CardContent>
                            </Card>}
                            {role === "Provider" && user.status === "Verified" && user?.application && !user.membership && <Card sx={{ my: 1 }}>
                                <CardContent sx={{ ...left_flex_box }}>
                                    <Info color="error" sx={{ mr: 1 }} /><Typography>You need to purchase a membership to use Ormedex!</Typography>
                                </CardContent>
                                <CardContent>
                                    <NavLink className={"a2"}>Press to view options</NavLink>
                                </CardContent>
                            </Card>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid container sx={{ ...centered_flex_box }} spacing={3} p={5}>
                    {(role === "Patient" || !role) && <>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card m={3}>
                                <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant="h6" fontWeight={"bold"} color={"var(--secColor)"} mr={1}>Find</Typography><Typography variant="h6">medical providers</Typography>
                                </CardContent>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Search sx={{ fontSize: 60 }} />
                                        </Grid>
                                        {/* <Grid item xs={6} sx={{ ...right_flex_box }}>
                                    <IconButton onClick={() => navigate("/patient/")} color='var(--secColor)'><ArrowForward /></IconButton>
                                </Grid> */}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card m={3}>
                                <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant="h6" fontWeight={"bold"} color={"var(--secColor)"} mr={1}>Book</Typography><Typography variant="h6">medical services</Typography>
                                </CardContent>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Today sx={{ fontSize: 60 }} />
                                        </Grid>
                                        {/* <Grid item xs={6} sx={{ ...right_flex_box }}>
                                    <IconButton onClick={() => navigate("/patient/")} color='var(--secColor)'><ArrowForward /></IconButton>
                                </Grid> */}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>}
                    {role === "Provider" && <>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <Card m={3}>
                                <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant="h6" fontWeight={"bold"} color={"var(--secColor)"} mr={1}>Showcase</Typography><Typography variant="h6">your services</Typography>
                                </CardContent>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <MedicalServicesOutlined sx={{ fontSize: 60 }} />
                                        </Grid>
                                        <Grid item xs={6} sx={{ ...right_flex_box }}>
                                            {/* <IconButton href={"https://ormedex.com/"} target='_blank' ><ArrowForward color='secondary' /></IconButton> */}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                    }
                    <Grid item xs={isMobile ? 12 : 4}>
                        <Card m={3}>
                            <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography variant="h6" fontWeight={"bold"} color={"var(--secColor)"} mr={1}>Buy</Typography><Typography variant="h6">medical supplies</Typography>
                            </CardContent>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <ShoppingBagOutlined sx={{ fontSize: 60 }} />
                                    </Grid>
                                    <Grid item xs={6} sx={{ ...right_flex_box }}>
                                        <IconButton href={"https://ormedex.com/"} target='_blank' ><ArrowForward color='secondary' /></IconButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <BottomBar />
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
