import { Add, Celebration, Diamond, Email, ExpandMore, Facebook, Phone, Send, Upload, Visibility, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
import { useNavigate } from "react-router-dom";

function OrderCard({ order, user }) {

    const navigate = useNavigate()

    return (
        <Grid
            item
            key={order._id}
            xs={isMobile ? 12 : 6}
        >
            <Card>
                <CardHeader
                    title={order.bundle.name}
                    subheader={`ID: ${order.paymobOrderId || order._id}`}
                    titleTypographyProps={{ textAlign: 'center' }}
                    action={order.bundle.name === 'Wedding' ? <Diamond /> : (order.bundle.name === "Prom" ? <Celebration /> : null)}
                    subheaderTypographyProps={{
                        textAlign: 'center',
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            ...centered_flex_box,
                            flexDirection: "column"
                        }}
                    >
                        {order.event ? <>
                            <Typography textAlign="center" component="h2" variant="h3" color="text.primary">
                                {order.event.name}
                            </Typography>
                            <Typography variant="h6" color="text.primary">
                                {moment(order.event.date).locale("en").format("ll")}
                            </Typography>
                        </> : <>
                            <Typography component="h2" variant="h3" color="text.primary">
                                No Event Yet
                            </Typography>
                        </>}
                    </Box>
                </CardContent>
                <CardActions>
                    <Button sx={{ ...main_button }} onClick={() => { order.event ? navigate(`/user/events/${order.event._id}`) : navigate(`/user/events/create/${order._id}`) }} fullWidth >
                        {order.event ? <Visibility sx={{marginX: 1}}/> : <Add sx={{marginX: 1}}/>}{order.event ? "View Event" : "Create Event"}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);