import { Celebration, Diamond, Email, ExpandMore, Facebook, Phone, Send, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
import { useNavigate } from "react-router-dom";

function InsuranceCard({ insurance }) {

    const navigate = useNavigate()

    return (
        <Grid
            item
            key={insurance._id}
            xs={isMobile ? 12 : 6}
        >
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={8} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Avatar src={insurance.provider.logo} sx={{ mr: 2 }} /> <Typography fontWeight={"bold"}>{insurance.provider.name}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <Chip color={insurance.status === "Verified" ? "success" : insurance.status === "Unverified" ? "error" : "warning"} label={insurance.status} />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Typography textAlign="center" fontSize={24} sx={{ fontFamily: "Kode Mono !important" }}>{insurance.number}</Typography>
                    <Typography color="text.secondary">{moment(insurance.coverageStartDate).format("YYYY-MM-DD")} Thru {moment(insurance.coverageExpiryDate).format("YYYY-MM-DD")}</Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCard);