import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store/store';
import { loadUser } from "./app/store/actions/authActions";
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import Buffer from 'buffer'
import AWS from 'aws-sdk';
import { Box, CircularProgress } from '@mui/material';
import { centered_flex_box } from './app/components/Styles';
import { getFavourites } from './app/store/actions/usersActions';
import { config } from './app/config';
store.dispatch(loadUser());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={
        <Box sx={{ ...centered_flex_box, height: "100vh" }}>
          <CircularProgress sx={{ color: "var(--secColor)" }} />
        </Box>
      }>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
