import * as React from 'react';
import { Typography, Table, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Pagination, ToggleButtonGroup, ToggleButton, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainDateInput, MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box } from '../../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getInsurances } from '../../app/store/actions/patientActions';
import { useState } from 'react';
import OrderCard from '../../app/components/OrderCard';
import { Add, DashboardCustomize, FilterAlt, Inbox, TableChart } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import InsuranceCard from '../../app/components/InsuranceCard';

export const Insurances = ({ getInsurances, insurances, isLoading }) => {

    const navigate = useNavigate()

    const initialFilters = {
        status: undefined,
    }
    const [filters, setfilters] = useState(initialFilters)

    useEffect(() => {
        getInsurances(filters)
    }, [filters])

    return (
        <Container component={"main"}>
            <Box sx={{ ...centered_flex_box }}>
                <Typography variant="h5" fontWeight="bold" textAlign="center">Registered Insurance</Typography><IconButton onClick={() => navigate("/patient/insurances/add")}><Add color="secondary" /></IconButton>
            </Box>
            {!isLoading && (
                <Box sx={{ ...centered_flex_box, mt: 2 }}>
                    <Grid container spacing={2}>
                        {insurances?.map((insurance) => {
                            return <InsuranceCard insurance={insurance} />
                        })}
                    </Grid>
                </Box>
            )}
            {isLoading && (
                <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            )}
        </Container >
    );
}

const mapStateToProps = (state) => ({
    insurances: state?.records?.insurance,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getInsurances };

export default connect(mapStateToProps, mapDispatchToProps)(Insurances);