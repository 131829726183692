import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { logIn, signUp } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import Logo from "../../app/components/logo.png"
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import ImageComponent from '../../app/components/ImageComponent';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const navigate = useNavigate();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            autoCapitalize="false"
            {...other}
        >
            {value === index && (
                { ...children }
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Login = ({ logIn, user, token, signUp, isLoading, close }) => {


    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);
    const [userType, setUserType] = React.useState("Patient");

    const handleSignupSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("password") !== data.get("password-confirm")) {
            setError("Password do not match")
        } else {
            const creation = {
                name: data.get("firstName") + " " + data.get("lastName"),
                birthDate: data.get("birthDate"),
                email: data.get("email"),
                mobile: data.get("mobile"),
                password: data.get("password"),
                type: data.get("type"),
                title: data.get("title"),
                add1: data.get("add1"),
                add2: data.get("add2"),
                city: data.get("city"),
                state: data.get("state"),
                postcode: data.get("postcode"),
                userType
            }
            signUp(creation, navigate);
            close && close()
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            email: data.get('email'),
            password: data.get('password')
        }
        logIn(details);
        close && close()
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (user) {
        try {
            window.ReactNativeWebView.postMessage(token);
        } catch (err) {
            console.log(err)
        }
        navigate("/")
    }

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ImageComponent preview={false} src={Logo} height={160} width={160} />
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs textColor='primary.main' value={value} centered onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Login" sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(0)} />
                            <Tab label="Sign Up" sx={{ mx: 3, fontWeight: "bold" }} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <MainInput
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <MainPassword
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                Login
                            </Button>
                            <Button onClick={() => navigate("/forgot-password")} fullWidth sx={{ color: "var(--secColor)" }}>
                                Forgot Password?
                            </Button>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box component="form" onSubmit={handleSignupSubmit} sx={{ mt: 1 }}>
                            <MainInput
                                margin="normal"
                                fullWidth
                                label="Sign up as"
                                value={userType}
                                onChange={({ target }) => setUserType(target.value)}
                                select
                            >
                                <MenuItem value={"Patient"}>
                                    <Typography>Patient</Typography>
                                </MenuItem>
                                <MenuItem value={"Provider"}>
                                    <Typography>Healthcare provider</Typography>
                                </MenuItem>
                            </MainInput>
                            {userType === "Provider" && <MainInput
                                margin="normal"
                                fullWidth
                                id="type"
                                label="Provider type"
                                name="type"
                                select
                            >
                                <MenuItem value={"Doctor"}>
                                    <Typography>Doctor</Typography>
                                </MenuItem>
                                <MenuItem value={"Pharmacy"}>
                                    <Typography>Pharmacy</Typography>
                                </MenuItem>
                                <MenuItem value={"Imaging"}>
                                    <Typography>Imaging</Typography>
                                </MenuItem>
                                <MenuItem value={"Lab"}>
                                    <Typography>Lab</Typography>
                                </MenuItem>
                            </MainInput>}
                            <Grid container spacing={2} sx={{ direction: "row" }}>
                                <Grid item xs={6}>
                                    <MainInput
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MainInput
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                    />
                                </Grid>
                            </Grid>
                            {userType === "Patient" && <MainInput
                                    margin="normal"
                                    type="date"
                                    required
                                    fullWidth
                                    id="birthDate"
                                    label="Birth Date"
                                    name="birthDate"
                                />}
                            <MainInput
                                margin="normal"
                                type="email"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                            />
                            <MuiPhoneNumber
                                sx={{
                                    "& label": {
                                        color: "var(--secColor) !important",
                                    },
                                    "& label.Mui-focused": {
                                        color: "var(--secColor) !important",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "1px solid var(--secColor)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "var(--terColor)",
                                        },

                                        borderRadius: 0.5,
                                    },
                                }}
                                margin="normal"
                                fullWidth
                                required
                                id="mobile"
                                label="Mobile Number"
                                name="mobile"
                                variant='outlined'
                                defaultCountry={'us'}
                                preferredCountries={['us']}
                                countryCodeEditable={false}
                                inputProps={{
                                    minLength: 13
                                }}
                                disableAreaCodes
                            />
                            <MainPassword
                                margin="normal"
                                sx={{ my: 1 }}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <MainPassword
                                margin="normal"
                                sx={{ my: 1 }}
                                required
                                fullWidth
                                name="password-confirm"
                                label="Confirm Password"
                                type="password"
                                id="password-confirm"
                                error={error}
                            />
                            {userType && <>
                                <hr />
                                <Typography textAlign="center">Address Information</Typography>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Address Title"
                                    name="title"
                                />
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="add1"
                                    label="Address Line 1"
                                    name="add1"
                                />
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    id="add2"
                                    label="Address Line 2"
                                    name="add2"
                                />
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    select
                                >
                                    <MenuItem value={"Alabama"}>
                                        <Typography>Alabama</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Alaska"}>
                                        <Typography>Alaska</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Arizona"}>
                                        <Typography>Arizona</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Arkansas"}>
                                        <Typography>Arkansas</Typography>
                                    </MenuItem>
                                    <MenuItem value={"California"}>
                                        <Typography>California</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Colorado"}>
                                        <Typography>Colorado</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Connecticut"}>
                                        <Typography>Connecticut</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Delaware"}>
                                        <Typography>Delaware</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Florida"}>
                                        <Typography>Florida</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Georgia"}>
                                        <Typography>Georgia</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Hawaii"}>
                                        <Typography>Hawaii</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Idaho"}>
                                        <Typography>Idaho</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Illinois"}>
                                        <Typography>Illinois</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Indiana"}>
                                        <Typography>Indiana</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Iowa"}>
                                        <Typography>Iowa</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Kansas"}>
                                        <Typography>Kansas</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Kentucky"}>
                                        <Typography>Kentucky</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Louisiana"}>
                                        <Typography>Louisiana</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Maine"}>
                                        <Typography>Maine</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Maryland"}>
                                        <Typography>Maryland</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Massachusetts"}>
                                        <Typography>Massachusetts</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Michigan"}>
                                        <Typography>Michigan</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Minnesota"}>
                                        <Typography>Minnesota</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Mississippi"}>
                                        <Typography>Mississippi</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Missouri"}>
                                        <Typography>Missouri</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Montana"}>
                                        <Typography>Montana</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Nebraska"}>
                                        <Typography>Nebraska</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Nevada"}>
                                        <Typography>Nevada</Typography>
                                    </MenuItem>
                                    <MenuItem value={"New Hampshire"}>
                                        <Typography>New Hampshire</Typography>
                                    </MenuItem>
                                    <MenuItem value={"New Jersey"}>
                                        <Typography>New Jersey</Typography>
                                    </MenuItem>
                                    <MenuItem value={"New Mexico"}>
                                        <Typography>New Mexico</Typography>
                                    </MenuItem>
                                    <MenuItem value={"New York"}>
                                        <Typography>New York</Typography>
                                    </MenuItem>
                                    <MenuItem value={"North Carolina"}>
                                        <Typography>North Carolina</Typography>
                                    </MenuItem>
                                    <MenuItem value={"North Dakota"}>
                                        <Typography>North Dakota</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Ohio"}>
                                        <Typography>Ohio</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Oklahoma"}>
                                        <Typography>Oklahoma</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Oregon"}>
                                        <Typography>Oregon</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Pennsylvania"}>
                                        <Typography>Pennsylvania</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Rhode Island"}>
                                        <Typography>Rhode Island</Typography>
                                    </MenuItem>
                                    <MenuItem value={"South Carolina"}>
                                        <Typography>South Carolina</Typography>
                                    </MenuItem>
                                    <MenuItem value={"South Dakota"}>
                                        <Typography>South Dakota</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Tennessee"}>
                                        <Typography>Tennessee</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Texas"}>
                                        <Typography>Texas</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Utah"}>
                                        <Typography>Utah</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Vermont"}>
                                        <Typography>Vermont</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Virginia"}>
                                        <Typography>Virginia</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Washington"}>
                                        <Typography>Washington</Typography>
                                    </MenuItem>
                                    <MenuItem value={"West Virginia"}>
                                        <Typography>West Virginia</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Wisconsin"}>
                                        <Typography>Wisconsin</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Wyoming"}>
                                        <Typography>Wyoming</Typography>
                                    </MenuItem>
                                </MainInput>
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                />
                                <MainInput
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="postcode"
                                    label="Postcode"
                                    name="postcode"
                                />
                            </>}
                            <Checkbox color='secondary' value={terms} onChange={() => setTerms(!terms)} />
                            <Button sx={{ ...sec_button }} href="/terms" target="_blank">
                                Accept Terms & Conditions
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={!terms}
                                sx={{ mt: 3, mb: 2, ...main_button }}
                            >
                                Sign Up
                            </Button>
                            <Box className="mb-2" />
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { logIn, signUp };

export default connect(mapStateToProps, mapDispatchToProps)(Login);