import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const wakeServer = () => () => {
    getRequest(undefined, undefined, undefined, endpoints.common.wake)
        .then((response) => {

        })
        .catch((err) => {
            notification.error({ message: "Server is down, please refresh", style: { marginTop: "15vh" } })
            console.log(err);
        });
};

export const getInsuranceProviders = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.common.insuranceProviders)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};