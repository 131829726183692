import * as React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sec_button } from "./Styles";

function AlertDialog({ open, handleAccept, handleClose, title, content }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{color: "var(--appBg)"}}
    >
      <DialogTitle sx={{fontWeight: "bold"}} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={"var(--appBg)"} id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{...sec_button}} onClick={handleClose}>Cancel</Button>
        <Button sx={{...sec_button}} onClick={handleAccept}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
