import { notification } from "antd";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";

export const ProtectedRoute = ({ auth, allowed }) => {
  var role = auth?.token?.split(' ')[0];
  
  const { eventId } = useParams()
  const cookie = Cookies.get(`guestId_${eventId}`);
  if (cookie !== undefined && allowed.includes("Guest")) {
    role = "Guest"
  }

  return (
    allowed.includes(role) ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    )
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
